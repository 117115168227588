<template>
    <div class="Invoices">
        <Headline title="Ihre Rechnungen auf einem Blick" />
        <!-- <Infobox
            title="Information"
            message="Die nächste Abbuchung erfolg am 01. September 2019"
            type="info"
        /> -->
        <invoices-table :invoices="invoices" @onInvoiceClick="handleInvoiceDownload" />
    </div>
</template>

<script type="text/javascript">
import Headline from '@/components/Headline';
import axios from 'axios';
import InvoicesTable from './components/InvoicesTable.vue';

export default {
    name: 'Invoice',
    components: {
        Headline,
        InvoicesTable,
    },
    data: () => ({
        invoices: [],
    }),
    created: function() {},
    methods: {
        handleDownload(type) {},
        async handleInvoiceDownload(id) {
            const requestUrl = `${process.env.VUE_APP_API_BASE_URL}/cpanel/invoices/${id.replaceAll(
                '/',
                '_',
            )}/pdf`;
            const link = document.createElement('a');
            link.href = requestUrl;
            link.target = '_blank';
            const filename = `${id}.pdf`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        },
        async handleFetchInvoices() {
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/invoices`;
            const result = await axios.get(url, {
                withCredentials: true,
            });
            this.$emit('onFinishLoading');

            this.invoices = result.data;
        },
    },
    mounted() {
        this.handleFetchInvoices();
    },
};
</script>

<style lang="scss">
.Invoices {
    @extend %contentWrapper;
    @extend %page;
}
</style>
