var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Row-Wrap",
    class: [{
      'is-empty': _vm.mInvoices.length === 0
    }]
  }, [_vm._l(_vm.mInvoices, function (invoice, i) {
    return _c('div', {
      key: i,
      staticClass: "Invoice-Wrap"
    }, [_c('Headline', [_c('h3', {
      staticStyle: {
        "margin": "0 0 8px 4px"
      }
    }, [_vm._v(_vm._s(invoice.year))])]), invoice.items.length > 0 ? _c('Row', {
      key: -1,
      staticClass: "Row-Header",
      attrs: {
        "parentHover": false,
        "items": {
          row: _vm.header
        },
        "index": -1
      }
    }) : _vm._e(), _vm._l(invoice.items, function (invoice, i) {
      return _c('Row', {
        key: invoice.invoiceNumber,
        attrs: {
          "parentHover": false,
          "items": invoice,
          "index": i
        },
        on: {
          "onClick": _vm.handleInvoiceClick
        }
      });
    })], 2);
  }), _vm.mInvoices.length === 0 ? _c('EmptyState') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }